<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="车型">
          <el-select style="width:200px" v-model="searchData.model" placeholder="请选择" size="small" clearable>
            <el-option v-for="item in modelList" :key="item.enumCode" :label="item.enumText" :value="item.enumCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width:200px" v-model="searchData.status" placeholder="请选择" size="small" clearable>
            <el-option label="上架" :value="1"> </el-option>
            <el-option label="下架" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.store_goods2_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'StoreGoods2Add' })"
      >新增
    </el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getAttrListAPI, getListAPI, deleteAPI, changeStatusAPI, } from './api'

const columns = [
  {
    label: '车型',
    prop: 'modelName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['modelName']}</p>
    }
  },
  {
    label: '颜色',
    prop: 'colorNames',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['colorNames']}</p>
    }
  },
  {
    label: '官网价',
    prop: 'officialPrice',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['officialPrice']}</p>
    }
  },
  {
    label: '买断金',
    prop: 'buyoutPrice',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['buyoutPrice']}</p>
    }
  },
  {
    label: '全新价格区间',
    prop: 'freshPriceScope',
    minWidth: '160',
    customRender(h, row) {
      return (
        <p>
          {row['minPrice'] !== row['maxPrice'] ? row['minPrice'] + '~' + row['maxPrice'] : row['minPrice']}
        </p>
      )
    }
  },
  {
    label: '封面',
    prop: 'coverImageUrl',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p>
          <el-image
            style="width: 60px; height: 60px;display:block;margin:5px 0;border-radius:5px"
            src={row['coverImageUrl']}
            preview-src-list={[row['coverImageUrl']]}
          ></el-image>
        </p>
      )
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.store_goods2_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={true}
            inactive-value={false}
            active-text="上架"
            inactive-text="下架"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '操作',
    minWidth: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin-left:10px;"
            disabled={row['status'] || !this.isShowBtn(this.AUTH_BTN.store_goods2_ist_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'StoreGoods2Edit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={row['status'] || !this.isShowBtn(this.AUTH_BTN.store_goods2_list_del)}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'StoreGoods2List',
  mixins: [authBtnMixin],
  data() {
    return {
      modelList: [],
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        model: '',
        status: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getAttrList()
    this.getList()
  },

  methods: {
    async getList() {
      const { model, status } = this.searchData
      let params = { goodsType: 2, model, status, page: this.currentPage, pageSize: this.pageSize }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    async getAttrList() {
      let params = { goodsType: 2, groupCode: 'model' }
      const res = await getAttrListAPI(params)
      this.modelList = res || []
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该门店商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == false ? 0 : 1 }
      let statusDesc = status == 0 ? '下架' : '上架'

      this.$confirm(`此操作将${statusDesc}该门店商品, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == false ? false : true
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == true ? false : true
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    showCode(row) {
      const { storeUrl, storeName } = row
      this.dialogVisible = true
      this.channelName = storeName
      this.$nextTick(function() {
        this.$refs.code.QRlink = storeUrl
        this.$refs.code.getQRcode()
      })
    },
    copyLink(url) {
      this.$copyText(url).then(
        () => {
          this.$message.success('复制成功')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    resetPw(id) {
      this.$set(this.resetPwData, 'id', id)
      this.dialogVisiblePw = true
    },
    savePw() {
      resetPwAPI(this.resetPwData).then(() => {
        this.dialogVisiblePw = false
        this.$message.success('重置成功')
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .el-form {
    .el-form-item {
      margin-right: 35px;
    }
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
